<template>
  <div style="text-align: left;">
    <el-tag style="margin-left: 20px; margin-top: 50px;"
            v-for="item in types"
            :key="item.name"
            closable
            @close="deleteType(item)">
      {{item.name}}
    </el-tag>
    <br />
    <el-popover placement="top"
                width="160"
                v-model="visible">
      <el-input v-model="name"
                clearable
                placeholder="Input status name"></el-input>
      <div style="text-align: right; margin-top: 10px;">
        <el-button size="mini"
                   type="text"
                   @click="visible = false, name = ''">Cancel</el-button>
        <el-button type="primary"
                   size="mini"
                   @click="addType">Confirm</el-button>
      </div>
      <el-button slot="reference"
                 style="margin-top: 50px; margin-left: 20px;"
                 type="primary"
                 size="small">Add Type</el-button>
    </el-popover>
  </div>
</template>
<script>
import http from '../utils/http'
export default {
  data() {
    return {
      visible: false,
      name: '',
      types: []
    }
  },
  mounted() {
    this.getType()
  },
  methods: {
    addType() {
      http.post('/type/addType', { name: this.name }).then(res => {
        if (res.data.status === 1) {
          this.$message({
            message: 'type was added successfully!',
            type: 'success'
          })
          this.types.push({ name: this.name })
          this.name = ''
          this.visible = false
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    deleteType(item) {
      http.post('/type/deleteType', { name: item.name }).then(res => {
        if (res.data.status === 1) {
          this.$message({
            message: 'the type was deleted successfully!',
            type: 'success'
          })
          this.types.splice(this.types.indexOf(item), 1)
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getType() {
      http.get('/type/').then(res => {
        if (res.data.status === 1) {
          this.types.splice(0, this.types.length)
          res.data.data.forEach(value => {
            this.types.push(value)
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>
<style scoped>
</style>